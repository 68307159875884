import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { gql, useQuery } from "@apollo/client";
import { use100vh } from "react-div-100vh";
import { PrismicRichText } from "@prismicio/react";

// Context
import { StoreContext } from "../../../components/context/store";
import { PageColor } from "../../../components/context/page-color";
import { HeaderColor } from "../../../components/context/header-color";

// Pages

// Components
import { Layout } from "../../../components/account/layout";
// import { OrdersList } from "../../../components/account/orders-list";
// import { DefaultAddress } from "../../../components/account/default-address";

// Layout
import {
  AccountNavigation,
  Page,
} from "../../../components/account/layout-components";

// SEO
import { PageSeo } from "../../../components/global/page-seo";

const CUSTOMER_ORDERS = gql`
  query ($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      email
      firstName
      lastName
      phone
      tags
      orders(first: 10) {
        edges {
          node {
            name
            totalPrice
            processedAt
            statusUrl
            currencyCode
            lineItems(first: 10) {
              edges {
                node {
                  title
                  quantity
                }
              }
            }
            shippingAddress {
              address1
              city
              lastName
              firstName
              zip
              country
            }
            subtotalPrice
            totalPrice
          }
        }
      }
    }
  }
`;

const OrderHistory = ({ data: accountData }) => {
  const { customerAccessToken } = useContext(StoreContext);

  const accessToken =
    customerAccessToken !== null ? customerAccessToken.accessToken : null;

  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);

  useEffect(() => {
    setPageColor(`#fff`);
    setHeaderColor(`#fff`);
  }, []);

  const { loading, error, data } = useQuery(CUSTOMER_ORDERS, {
    variables: { customerAccessToken: accessToken },
  });

  const height = use100vh();

  if (loading || error)
    return (
      <Layout>
        <PageSeo
          title={`Historique des commandes`}
          image={null}
          description={null}
          url={null}
        />

        <Page className="page logged-in" height={height}>
          <AccountNavigation data={data} />
        </Page>
      </Layout>
    );

  return (
    <Layout>
      <PageSeo
        title={`Historique des commandes`}
        image={null}
        description={null}
        url={null}
      />

      <Page className="page logged-in" height={height}>
        <AccountNavigation data={data} />

        <div className="content-container">
          <div className="inner-content-container">
            <div className="content">
              <h3>Historique des commandes</h3>

              <PrismicRichText
                field={
                  accountData.prismicAccounts.data.order_history_text.richText
                }
              />
            </div>
          </div>
        </div>
      </Page>
    </Layout>
  );
};

export default OrderHistory;

export const query = graphql`
  {
    prismicAccounts(lang: { eq: "fr-fr" }) {
      data {
        order_history_text {
          richText
        }
      }
    }
  }
`;
